import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ intl }) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: "notfound.title" })} />
    <h1>{intl.formatMessage({ id: "notfound.header" })}</h1>
    <p>{intl.formatMessage({ id: "notfound.description" })}.</p>
  </Layout>
)

export default injectIntl(NotFoundPage)
